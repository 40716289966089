import { ConditionTypes } from "../constants";

const evaluateCondition = (condition, conditionValue, userAnswer) => {
  console.log(condition);
  condition = ConditionTypes[condition]??"Yes/No";
  switch (condition) {
    case ConditionTypes.YES_NO:
      console.log(userAnswer?.toLowerCase(), conditionValue?.toLowerCase());
      return userAnswer?.toLowerCase() === conditionValue?.toLowerCase();

    case ConditionTypes.MULTIPLE_CHOICE:
      // For multiple choice, we might want to check if the answer matches exactly
      // or is included in a list of valid answers
      return userAnswer === conditionValue;

    case ConditionTypes.KEYWORD:
      const { keywords } = conditionValue;
      const haystack = keywords.split(",");
      const found = haystack.find(
        (keyword) => keyword.toLowerCase() === userAnswer.toLowerCase(),
      );
      return !!found;

    case ConditionTypes.LENGTH:
      const length = parseInt(userAnswer.length);
      const compare_length = parseFloat(conditionValue.value ?? 0);
      let type = "lessthan";
      if (conditionValue.type) {
        type = conditionValue.type.toLowerCase(); // lessThan / moreThan / exactly
      }

      if (type === "lessthan") {
        return (
          !isNaN(length) && !isNaN(compare_length) && length <= compare_length
        );
      } else if (type === "morethan") {
        return (
          !isNaN(length) && !isNaN(compare_length) && length >= compare_length
        );
      }
      return false;
    case ConditionTypes.RATING:
      // For ratings, we might want to check if the rating is >= or <= a certain value
      const userRating = parseInt(userAnswer);
      const threshold = parseFloat(conditionValue.value ?? 5);
      let conditionType = "lessthan";
      if (conditionValue.type) {
        conditionType = conditionValue.type.toLowerCase(); // lessThan / moreThan / exactly
      }

      if (conditionType === "lessthan") {
        return (
          !isNaN(userRating) && !isNaN(threshold) && userRating <= threshold
        );
      } else if (conditionType === "morethan") {
        return (
          !isNaN(userRating) && !isNaN(threshold) && userRating >= threshold
        );
      } else {
        return (
          !isNaN(userRating) && !isNaN(threshold) && userRating === threshold
        );
      }

    case ConditionTypes.NUMERIC:
      // For numeric values, we might want to support operations like >, <, =, etc.
      const [operation, value] = conditionValue.split(":");
      const numericAnswer = parseFloat(userAnswer);
      const numericValue = parseFloat(value);

      switch (operation) {
        case "gt":
          return numericAnswer > numericValue;
        case "lt":
          return numericAnswer < numericValue;
        case "eq":
          return numericAnswer === numericValue;
        case "gte":
          return numericAnswer >= numericValue;
        case "lte":
          return numericAnswer <= numericValue;
        default:
          return false;
      }
    default:
      return false;
  }
};

export default evaluateCondition;
