import { api } from "../utils/api";

const StripeService = {
  createSubscription: async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.post(
        `/api/companies/subscription`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const subscription = response.data;
      return subscription;
    } catch (error) {
      throw new Error("Some error occurred)");
    }
  },

  getSubscription: async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.get(`/api/companies/subscription`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const subscription = response.data;
      return subscription;
    } catch (error) {
      throw new Error("Some error occurred");
    }
  },
};
export default StripeService;
