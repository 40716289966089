import React, { useState } from "react";
import { Mic, MicOff } from "lucide-react"; // Importing microphone icons
import SpeechToTextService from "../services/SpeechToTextService";
import CustomSelect from "../components/CustomSelect";
import { generateDescription } from "../utils";

const JobDescriptionStep = ({ formData, onChange, checkFieldInvalid }) => {
  const [isListening, setIsListening] = useState(false);
  const [descriptionLength, setDescriptionLength] = useState({ id: 120 });
  const [isLoadingDescription, setIsLoadingDescription] = useState(false);

  const handleToggleListening = async () => {
    if (isListening) {
      // Stop listening
      setIsListening(false);
    } else {
      // Start listening
      setIsListening(true);
      try {
        const transcription = await SpeechToTextService.startRecognition();
        onChange("description", formData.description + " " + transcription);
      } catch (error) {
        console.error("Error during speech recognition:", error);
      } finally {
        setIsListening(false);
      }
    }
  };

  const handleDescriptionGeneratorClick = async () => {
    setIsLoadingDescription(true);
    const response = await generateDescription(formData, descriptionLength.id);
    if (response && "description" in response) {
      onChange("description", response?.description);
    }
    setIsLoadingDescription(false);
  };

  const safeFormData = {
    ...formData,
    post_cvl: formData.post_cvl !== undefined ? formData.post_cvl : true, // Default to true
  };

  return (
    <div className="flex flex-col gap-4 max-w-full">
      <div className="hidden">
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            className="rounded"
            checked={safeFormData.post_cvl}
            onChange={(e) => onChange("post_cvl", e.target.checked)}
          />
          <span className="text-sm">Post over CV-Library</span>
        </label>
      </div>
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Job Description <span className="text-red-500 font-bold">*</span>
        </label>
        <small className="block text-gray-500 mb-2">
          Try to add more than 300 characters to the job description. A good
          description gets more relevant candidates and helps your job posting
          stand out.
        </small>
        <div className="relative">
          <textarea
            className={`w-full px-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("description") ? "border-red-500" : "border-gray-300"}`}
            rows={15}
            value={formData.description}
            onChange={(e) => onChange("description", e.target.value)}
            placeholder="Describe the role, responsibilities, and what you're looking for in an ideal candidate..."
          />
          <button
            type="button"
            onClick={handleToggleListening}
            className={`absolute bottom-3 right-2 p-3 rounded-full transition-all duration-300 ${
              isListening
                ? "bg-red-500 text-white animate-pulse shadow-lg"
                : "bg-blue-500 text-white"
            }`}
          >
            {isListening ? <MicOff size={28} /> : <Mic size={28} />}
          </button>
        </div>
        <div className="text-right text-sm text-gray-500">
          {formData.description.length} characters
          {formData.description.length < 50 && " (minimum 50 recommended)"}
        </div>
      </div>
      <div className="flex gap-4 items-center justify-center w-full max-w-xl mx-auto p-4">
        <CustomSelect
          options={[
            { id: 50, name: "Short (50 words)" },
            { id: 120, name: "Medium (120 words)" },
            { id: 240, name: "Long (240 words)" },
          ]}
          value={descriptionLength} // Assuming you have a selectedJobId state variable
          placeholder="Description Length"
          className="w-50"
          isMulti={false}
          onChange={(value) => {
            setDescriptionLength({ id: value });
          }}
          // Adding custom styles to match the original select's appearance
        />
        <button
          onClick={handleDescriptionGeneratorClick}
          disabled={isLoadingDescription}
          className={`px-6 py-2 bg-indigo-500 text-white rounded-lg transition-all duration-200 flex items-center gap-2 
        ${isLoadingDescription ? "opacity-75 cursor-not-allowed" : "hover:bg-indigo-600 hover:scale-105"} 
        focus:ring-2 focus:ring-indigo-300 focus:outline-none`}
          aria-label="Generate Description"
        >
          {isLoadingDescription ? (
            <>
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              <span>Generating...</span>
            </>
          ) : (
            "Generate Description"
          )}
        </button>
      </div>
      <em className="m-auto">Pick length & generate job description</em>
    </div>
  );
};

export default JobDescriptionStep;
