// constants.js

export const AnswerTypes = {
  OPEN_ENDED: "Open-ended",
  YES_NO: "Yes/No",
  MULTIPLE_CHOICE: "Multiple Choice",
  MULTIPLE_CHOICE_MULTI_ANSWER: "Multiple Choice Multi-Answer",
  MULTIPLE_CHOICE_OTHER: "Multiple Choice Other",
  RANKED: "Ranked",
  RATING: "Rating",
  FILE: "File",
  DATE: "Date",
  TIME: "Time",
  DATE_TIME: "Date and Time",
  NUMERIC: "Numeric",
  EMAIL: "Email",
  PHONE: "Phone",
  URL: "URL",
  IMAGE: "Image",
};

export const DifficultyLevels = {
  EASY: "Easy",
  MEDIUM: "Medium",
  HARD: "Hard",
};

export const ImportanceLevels = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  CRITICAL: "Critical",
};

export const QuestionTypes = {
  ASSESSMENT: "Assessment",
  PROFILE: "Profile",
  QUALIFICATION: "Qualification",
  DOCUMENTATION: "Documentation",
  SETTING: "Setting",
  jobseeker_registration: "Jobseeker (Registration)",
};

export const ConditionTypes = {
  YES_NO: "Yes/No",
  KEYWORD: "Keyword",
  RATING: "Rating",
  //CONFIDENCE: "Confidence",
  //COMPLETENESS: "Completeness",
  LENGTH: "Length",
};

export const ProgressColors = [
  { threshold: 0, color: "bg-red-600" },
  { threshold: 20, color: "bg-orange-500" },
  { threshold: 40, color: "bg-yellow-500" },
  { threshold: 60, color: "bg-yellow-300" },
  { threshold: 80, color: "bg-green-400" },
  { threshold: 100, color: "bg-green-600" },
];

export const ColorMap = {
  blue: {
    bg: "bg-blue-50",
    bgDark: "bg-blue-100",
    border: "border-blue-200",
    text: "text-blue-600",
    textDark: "text-blue-700",
    gradient: "from-blue-50 to-blue-100/50",
    hover: "hover:bg-blue-100",
    ring: "ring-blue-500",
    ringOffset: "ring-offset-blue-100",
    focus: "focus:ring-blue-500",
  },
  purple: {
    bg: "bg-purple-50",
    bgDark: "bg-purple-100",
    border: "border-purple-200",
    text: "text-purple-600",
    textDark: "text-purple-700",
    gradient: "from-purple-50 to-purple-100/50",
    hover: "hover:bg-purple-100",
    ring: "ring-purple-500",
    ringOffset: "ring-offset-purple-100",
    focus: "focus:ring-purple-500",
  },
  green: {
    bg: "bg-green-50",
    bgDark: "bg-green-100",
    border: "border-green-200",
    text: "text-green-600",
    textDark: "text-green-700",
    gradient: "from-green-50 to-green-100/50",
    hover: "hover:bg-green-100",
    ring: "ring-green-500",
    ringOffset: "ring-offset-green-100",
    focus: "focus:ring-green-500",
  },
  red: {
    bg: "bg-red-50",
    bgDark: "bg-red-100",
    border: "border-red-200",
    text: "text-red-600",
    textDark: "text-red-700",
    gradient: "from-red-50 to-red-100/50",
    hover: "hover:bg-red-100",
    ring: "ring-red-500",
    ringOffset: "ring-offset-red-100",
    focus: "focus:ring-red-500",
  },
  orange: {
    bg: "bg-orange-50",
    bgDark: "bg-orange-100",
    border: "border-orange-200",
    text: "text-orange-600",
    textDark: "text-orange-700",
    gradient: "from-orange-50 to-orange-100/50",
    hover: "hover:bg-orange-100",
    ring: "ring-orange-500",
    ringOffset: "ring-offset-orange-100",
    focus: "focus:ring-orange-500",
  },
  yellow: {
    bg: "bg-yellow-50",
    bgDark: "bg-yellow-100",
    border: "border-yellow-200",
    text: "text-yellow-600",
    textDark: "text-yellow-700",
    gradient: "from-yellow-50 to-yellow-100/50",
    hover: "hover:bg-yellow-100",
    ring: "ring-yellow-500",
    ringOffset: "ring-offset-yellow-100",
    focus: "focus:ring-yellow-500",
  },
  indigo: {
    bg: "bg-indigo-50",
    bgDark: "bg-indigo-100",
    border: "border-indigo-200",
    text: "text-indigo-600",
    textDark: "text-indigo-700",
    gradient: "from-indigo-50 to-indigo-100/50",
    hover: "hover:bg-indigo-100",
    ring: "ring-indigo-500",
    ringOffset: "ring-offset-indigo-100",
    focus: "focus:ring-indigo-500",
  },
  pink: {
    bg: "bg-pink-50",
    bgDark: "bg-pink-100",
    border: "border-pink-200",
    text: "text-pink-600",
    textDark: "text-pink-700",
    gradient: "from-pink-50 to-pink-100/50",
    hover: "hover:bg-pink-100",
    ring: "ring-pink-500",
    ringOffset: "ring-offset-pink-100",
    focus: "focus:ring-pink-500",
  },
  gray: {
    bg: "bg-gray-50",
    bgDark: "bg-gray-100",
    border: "border-gray-200",
    text: "text-gray-600",
    textDark: "text-gray-700",
    gradient: "from-gray-50 to-gray-100/50",
    hover: "hover:bg-gray-100",
    ring: "ring-gray-500",
    ringOffset: "ring-offset-gray-100",
    focus: "focus:ring-gray-500",
  },
  slate: {
    bg: "bg-slate-50",
    bgDark: "bg-slate-100",
    border: "border-slate-200",
    text: "text-slate-600",
    textDark: "text-slate-700",
    gradient: "from-slate-50 to-slate-100/50",
    hover: "hover:bg-slate-100",
    ring: "ring-slate-500",
    ringOffset: "ring-offset-slate-100",
    focus: "focus:ring-slate-500",
  },
};

// StatusColors maps different status states to their respective colors
export const StatusColors = {
  success: ColorMap.green,
  warning: ColorMap.yellow,
  error: ColorMap.red,
  info: ColorMap.blue,
  pending: ColorMap.orange,
  default: ColorMap.gray,
};

export const referenceCategories = [
  { id: "communication", label: "Communication Skills" },
  { id: "timekeeping", label: "Timekeeping & Punctuality" },
  { id: "teamwork", label: "Teamwork" },
  { id: "workQuality", label: "Quality of Work" },
  { id: "rehire", label: "Would you hire him/her again?" },
];
