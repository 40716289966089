import React, { useEffect } from "react";
import { getTokenDetail, rejectReference, updateTokenStatus } from "./utils";

const ReferenceReject = ({ token }) => {
  useEffect(() => {
    const fetchToken = async () => {
      let _token = await getTokenDetail(token, "reference");
      if (_token.used || new Date(_token.expiresAt) < new Date()) {
        //_token = false;
      }
      if (_token) {
        const searchParams = new URLSearchParams(window.location.search);
        const request = searchParams.get("request");
        await rejectReference(token, request);
        await updateTokenStatus(token, true);
      }
    };

    fetchToken();
  }, [token]);
  return (
    <div className="w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="/whoppit-logo.png"
          alt="Whoppit"
        />
        <h6 className="mt-6 text-center text-xl font-bold text-gray-900">
          Reference request rejected.
        </h6>
      </div>
    </div>
  );
};

export default ReferenceReject;
