import React, { useEffect, useState, useCallback } from "react";
import JobDataService from "../services/JobDataService";
import CustomCreatableSelect from "../components/CustomCreatableSelect";
import CustomSelect from "../components/CustomSelect";
import JobService from "../services/JobService";
import { Tooltip } from "react-tooltip";
import { Info } from "lucide-react";

const RequirementsStep = ({ formData, onChange, checkFieldInvalid }) => {
  const [qualifications, setQualifications] = useState([]);
  const [preferredQualfication, setPreferredQualfication] = useState([]);
  const [skills, setSkills] = useState([]);
  const [additionalRequirements, setAdditionalRequirements] = useState([]);

  // First, define loadOptions with useCallback
  const loadOptions = useCallback(async () => {
    try {
      const qualificationsData = await JobDataService.getQualifications(
        formData.company_id,
        "main",
      );

      const preferredQualificationsData =
        await JobDataService.getQualifications(
          formData.company_id,
          "preferred",
        );
      const skillsData = await JobDataService.getSkills();
      const additional_requirements =
        await JobDataService.additionalRequirements(formData.company_id);

      setQualifications(qualificationsData);
      setPreferredQualfication(preferredQualificationsData);
      setSkills(skillsData);
      setAdditionalRequirements(additional_requirements);
    } catch (error) {
      console.error("Error loading qualifications and skills:", error);
    }
  }, [formData.company_id]); // Add dependencies that loadOptions relies on

  // Then use it in useEffect
  useEffect(() => {
    loadOptions();
  }, [loadOptions]); // Add loadOptions as a dependency

  const safeFormData = {
    ...formData,
    qualifications: Array.isArray(formData.qualifications)
      ? formData.qualifications
      : [],
    preferred_qualifications: Array.isArray(formData.preferred_qualifications)
      ? formData.preferred_qualifications
      : [],
    preferred_skills: Array.isArray(formData.preferred_skills)
      ? formData.preferred_skills
      : [],
    cv_required:
      formData.cv_required !== undefined ? formData.cv_required : true, // Default to true
    is_references_required:
      formData.is_references_required !== undefined
        ? formData.is_references_required
        : true, // Default to true
    references_required_options: formData.references_required_options || "2", // Default to 2 references
    pre_screening_questions: Boolean(formData.pre_screening_questions),
    automatic_referencing_enabled:
      formData.automatic_referencing_enabled !== undefined
        ? formData.automatic_referencing_enabled
        : true, // Default to true
  };

  const handleCreateQualification = async (inputValue, type) => {
    try {
      const response = await JobService.recordQualification(
        formData.company_id,
        inputValue,
        type,
      );
      // Update your qualifications list
      const newQualification = {
        id: response.value,
        name: response.name,
      };

      if (type === "main") {
        setQualifications((prev) => [...prev, newQualification]);
        const updatedValues = [
          ...safeFormData.qualifications,
          newQualification.id,
        ];
        onChange("qualifications", updatedValues);
      } else {
        setPreferredQualfication((prev) => [...prev, newQualification]);
        const updatedValues = [
          ...safeFormData.preferred_qualifications,
          newQualification.id,
        ];
        onChange("preferred_qualifications", updatedValues);
      }

      // Update the form with the new value included
    } catch (error) {
      console.error("Error creating new qualification:", error);
      // Handle error appropriately
    }
  };

  const handleCreateRequirement = async (inputValue) => {
    try {
      const response = await JobService.recordCustomRequirement(
        formData.company_id,
        inputValue,
      );
      // Update your qualifications list
      const newRequirement = {
        id: response.value,
        value: response.name,
      };
      setAdditionalRequirements((prev) => [...prev, newRequirement]);
      const updatedValues = [
        ...safeFormData.additional_requirements,
        newRequirement.id,
      ];
      onChange("additional_requirements", updatedValues);
      // Update the form with the new value included
    } catch (error) {
      console.error("Error creating new qualification:", error);
      // Handle error appropriately
    }
  };

  return (
    <div className="flex flex-col gap-4 max-w-[456px]">
      {/* Qualifications */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-1">
          Qualifications <span className="text-red-500 font-bold">*</span>
        </label>
        <p className="text-xs text-gray-500 mb-2">
          Hold down the Ctrl (Windows) or Command (Mac) button to select
          multiple options.
        </p>
        <CustomCreatableSelect
          options={qualifications}
          value={safeFormData.qualifications}
          onChange={(values) => onChange("qualifications", values)}
          onCreateOption={(inputValue) =>
            handleCreateQualification(inputValue, "main")
          }
          isInvalid={checkFieldInvalid("qualifications")}
          placeholder="Select or create qualifications..."
          height="1rem"
        />
      </div>

      {/* Preferred Qualifications */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-1">
          Preferred Qualifications
        </label>
        <p className="text-xs text-gray-500 mb-2">
          Hold down the Ctrl (Windows) or Command (Mac) button to select
          multiple options.
        </p>
        <CustomCreatableSelect
          options={preferredQualfication}
          value={safeFormData.preferred_qualifications}
          onChange={(values) => onChange("preferred_qualifications", values)}
          onCreateOption={(inputValue) =>
            handleCreateQualification(inputValue, "preferred")
          }
          placeholder="Select or create preferred qualifications..."
          height="1rem"
        />
      </div>

      {/* Additional Requirements */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Additional Requirements
        </label>

        <CustomCreatableSelect
          options={additionalRequirements.map((req) => {
            return { id: req.id, name: req.value };
          })}
          value={safeFormData.additional_requirements}
          onChange={(values) => onChange("additional_requirements", values)}
          onCreateOption={(inputValue) => handleCreateRequirement(inputValue)}
          height="1rem"
          placeholder="Select or create Requirement..."
        />
      </div>

      {/* Preferred Skills */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-1">
          Preferred Skills
        </label>
        <p className="text-xs text-gray-500 mb-2">
          Hold down the Ctrl (Windows) or Command (Mac) button to select
          multiple options.
        </p>
        <CustomSelect
          options={skills}
          value={safeFormData.preferred_skills}
          onChange={(values) => onChange("preferred_skills", values)}
          placeholder="Select preferred skills"
          className=""
          isMulti={true}
          isInvalid={checkFieldInvalid?.("preferred_skills")}
        />
      </div>

      {/* Experience */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Minimum Years of Experience
        </label>
        <CustomSelect
          options={[
            "0",
            "1 year",
            "2 years",
            "3 years",
            "5+ years",
            "10+ years",
          ]}
          value={safeFormData.min_year_exp || ""}
          onChange={(value) => onChange("min_year_exp", value)}
          isMulti={false}
          placeholder="Select Experience"
          className="w-full"
        />
      </div>

      {/* Education */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Education
        </label>
        <CustomSelect
          options={[
            { id: "High School", name: "High School" },
            { id: "Bachelor's Degree", name: "Bachelor's Degree" },
            { id: "Master's Degree", name: "Master's Degree" },
            { id: "PhD", name: "PhD" },
          ]}
          value={safeFormData.education || ""}
          onChange={(value) => onChange("education", value)}
          isMulti={false}
          placeholder="Select Education Level"
          className="w-full"
        />
      </div>

      {/* Remote Work */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Remote Work
        </label>
        <CustomSelect
          options={[
            { id: "No remote work", name: "No remote work" },
            { id: "Hybrid", name: "Hybrid" },
            { id: "Fully remote", name: "Fully remote" },
          ]}
          value={safeFormData.remoteType || ""}
          onChange={(value) => onChange("remoteType", value)}
          isMulti={false}
          placeholder="Select Remote Work Option"
          className="w-full"
        />
      </div>

      {/* CV Required */}
      <div>
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={safeFormData.cv_required}
            onChange={(e) => onChange("cv_required", e.target.checked)}
            className="rounded"
          />
          <span className="text-sm">CV Required</span>
        </label>
      </div>

      {/* References */}
      <div>
        <div className="flex flex-row gap-10">
          <label className="flex items-center gap-2 mb-2">
            <input
              type="checkbox"
              checked={safeFormData.is_references_required}
              onChange={(e) =>
                onChange("is_references_required", e.target.checked)
              }
              className="rounded"
            />
            <span className="text-sm">References Required</span>
          </label>

          <label className="flex items-center gap-2 mb-2">
            <input
              type="checkbox"
              className="rounded"
              checked={safeFormData.automatic_referencing_enabled}
              onChange={(e) =>
                onChange("automatic_referencing_enabled", e.target.checked)
              }
            />
            <span className="text-sm">Automatic Referencing?</span>
            <Info
              className="w-4 h-4 text-gray-600"
              data-tooltip-id="automatic_referencing_tooltip"
              data-tooltip-content="If set to ON, we will email any references provided by the candidate on your behalf. They will fill out a short form"
              data-tooltip-place="top"
            />
          </label>

          <Tooltip id="automatic_referencing_tooltip" />
        </div>

        {safeFormData.is_references_required && (
          <CustomSelect
            className="w-full"
            isMulti={false}
            value={{
              id: safeFormData.references_required_options,
              name: `${safeFormData.references_required_options} Reference${safeFormData.references_required_options === "1" ? "" : "s"}`,
            }}
            onChange={(value) => onChange("references_required_options", value)}
            options={[
              { id: "1", name: "1 Reference" },
              { id: "2", name: "2 References" },
              { id: "3", name: "3 References" },
            ]}
            placeholder="Select number of references"
          />
        )}
      </div>

      {/* Pre-screening Questions */}
      {/* 
      <div>
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={safeFormData.pre_screening_questions}
            onChange={(e) => onChange('pre_screening_questions', e.target.checked)}
            className="rounded"
          />
          <span className="text-sm">Include Pre-screening Questions</span> 
        </label>
      </div>
      */}
    </div>
  );
};

export default RequirementsStep;
