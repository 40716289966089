import React, { useRef, useEffect, useState } from "react";
import { Send, UploadIcon } from "lucide-react";
import { Message } from "./components/Message";
import { FileUpload } from "./components/FileUpload";
import { useChatState } from "./hooks/useChatState";
import { QuestionInput } from "./components/QuestionInput";
import { useQuestionLoader } from "./hooks/useQuestionLoader";
import { AnswerTypes } from "./constants";
import { Tooltip } from "react-tooltip";

const ChatInterface = ({ userType }) => {
  const {
    messages,
    currentQuestion,
    userAnswer,
    uploadedFiles,
    isUploading,
    handleSendAnswer,
    setUserAnswer,
    setUploadedFiles,
    setMessages,
    setCurrentQuestion,
    setQuestionQueue,
  } = useChatState(userType);

  const {
    isLoading,
    isTokenValid,
    loadedMessages,
    loadedQuestions,
    initialQuestion,
  } = useQuestionLoader(userType);

  const messagesEndRef = useRef(null);
  const textAreaRef = useRef(null);
  const sendButtonRef = useRef(null);
  const [chatAttachment] = useState(false);
  const fileInputRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleUploadIconClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (loadedMessages && loadedQuestions) {
      setMessages(loadedMessages);
      setQuestionQueue(loadedQuestions);
      setCurrentQuestion(initialQuestion);
    }
  }, [
    loadedMessages,
    loadedQuestions,
    initialQuestion,
    setMessages,
    setQuestionQueue,
    setCurrentQuestion,
  ]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleUploadAttachments = (event) => {
    const files = Array.from(event.target.files).map((file) =>
      Object.assign(file, {
        customType: currentQuestion.category ?? "document",
      }),
    );
    setUploadedFiles((prev) => [...prev, ...files]);
    event.target.value = "";
  };

  useEffect(() => {
    setTooltipOpen(false);
    if (
      userAnswer === "" ||
      (Array.isArray(userAnswer) && userAnswer.length === 0)
    ) {
      setTooltipOpen(false);
    } else {
      setTooltipOpen(true);
    }
  }, [userAnswer]);

  const isAnswerEmpty = () => {
    if (!userAnswer && uploadedFiles.length > 0) return false;
    if (
      currentQuestion?.answer_type?.toLowerCase() ===
      AnswerTypes.MULTIPLE_CHOICE_MULTI_ANSWER.toLowerCase()
    ) {
      return Array.isArray(userAnswer) ? userAnswer.length === 0 : true;
    }
    return typeof userAnswer === "string" ? !userAnswer.trim() : false;
  };

  const handleSkip = () => {
    // Implement skip functionality if needed
  };

  if (isLoading) {
    return (
      <div className="flex-grow flex justify-center items-center">
        Loading questions...
      </div>
    );
  }

  if (!isTokenValid) {
    return (
      <div className="flex-grow flex justify-center items-center">
        Link is expired or used
      </div>
    );
  }

  return (
    <div className="flex-grow flex justify-center overflow-hidden lg:p-8">
      <div className="w-full md:max-w-3xl flex flex-col bg-white shadow-lg md:mt-2 md:mb-4 md:rounded-lg">
        <header className="mt-10 sm:mx-auto sm:w-full sm:max-w-md sm:mt-10 md:mt-0 pb-10">
          <img
            className="mx-auto h-12 w-auto"
            src="./whoppit-logo.png"
            alt="Whoppit"
          />
        </header>

        <div
          className="flex-grow overflow-y-auto p-4"
          style={{ maxHeight: "calc(100vh - 160px)" }}
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`mb-4 ${message.sender === "user" ? "text-right" : "text-left"}`}
            >
              <Message
                message={message}
                onSkip={message.required === false ? handleSkip : undefined}
              />
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        {currentQuestion && (
          <footer className="border-t border-gray-200 p-4">
            <div className="mb-2">
              {uploadedFiles.length > 0 && (
                <FileUpload
                  files={uploadedFiles}
                  onRemove={(file) =>
                    setUploadedFiles((prev) => prev.filter((f) => f !== file))
                  }
                />
              )}
            </div>

            <div className="flex items-start items-center">
              <div className="flex-grow mr-2 w-full">
                <QuestionInput
                  question={currentQuestion}
                  value={userAnswer}
                  onChange={setUserAnswer}
                  onFileUpload={handleUploadAttachments}
                  textAreaRef={textAreaRef}
                  chatAttachment={chatAttachment}
                />
              </div>
              {currentQuestion?.question?.promt_file_upload ? (
                <div className="flex items-center gap-4">
                  <input
                    type="file"
                    multiple
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleUploadAttachments}
                  />
                  <button
                    onClick={handleUploadIconClick}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                  >
                    <UploadIcon
                      className="w-6 h-6 text-gray-600"
                      data-tooltip-id="promt_file_upload_tooltip"
                      data-tooltip-content={
                        currentQuestion?.question
                          ?.promt_file_upload_helper_text ??
                        "Please upload evidence of this by uploading one or more files."
                      }
                      data-tooltip-place="bottom"
                      onMouseEnter={() => setTooltipOpen(false)}
                    />
                    <Tooltip
                      id="promt_file_upload_tooltip"
                      isOpen={tooltipOpen}
                    />
                  </button>
                  <span className="text-sm text-gray-600"></span>
                </div>
              ) : (
                ""
              )}
              <button
                ref={sendButtonRef}
                onClick={handleSendAnswer}
                disabled={isAnswerEmpty() || isUploading}
                className={`bg-blue-500 text-white h-14 w-14 flex items-center justify-center rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isAnswerEmpty() || isUploading
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                {isUploading ? (
                  <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white" />
                ) : (
                  <Send size={24} />
                )}
              </button>
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default ChatInterface;
