import React, { useState, useEffect } from "react";
import { Star } from "lucide-react";
import { getTokenDetail, saveReference, updateTokenStatus } from "./utils";
import { referenceCategories } from "./constants";

const ReferenceForm = ({ token }) => {
  const [ratings, setRatings] = useState({
    communication: 0,
    timekeeping: 0,
    teamwork: 0,
    workQuality: 0,
    rehire: 0,
  });
  const [comment, setComment] = useState("");
  const [tokenDetail, setTokenDetail] = useState(null);
  const [tokenNotFound, setTokenNotFound] = useState(false);
  const [referecneSaved, setReferecneSaved] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      let _token = await getTokenDetail(token, "reference");
      if (_token.used || new Date(_token.expiresAt) < new Date()) {
        _token = false;
      }
      if (!_token) {
        setTokenNotFound(true);
      } else {
        setTokenDetail(_token);
      }
    };

    fetchToken();
  }, [token]);

  const handleRatingChange = (category, value) => {
    setRatings((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(window.location.search);
    const request = searchParams.get("request");
    console.log(request);
    await saveReference(ratings, comment, tokenDetail.userId, request);
    setReferecneSaved(true);
    await updateTokenStatus(token, true);
    // Handle form submission here
  };

  const StarRating = ({ category, value }) => {
    return (
      <div className="flex gap-1">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            type="button"
            onClick={() => handleRatingChange(category, star)}
            className="focus:outline-none"
          >
            <Star
              className={`w-6 h-6 ${star <= ratings[category]
                  ? "fill-yellow-400 text-yellow-400"
                  : "text-gray-300"
                }`}
            />
          </button>
        ))}
      </div>
    );
  };

  return tokenNotFound ? (
    <div className="w-full text-center mt-10">Token Not Found or Expired</div>
  ) : (
    <div className="w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="/whoppit-logo.png"
          alt="Whoppit"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reference Request
        </h2>
      </div>
      {referecneSaved ? (
        <div className="p-10 w-full text-center">
          {" "}
          Thank you! Your reference is saved.
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          {referenceCategories.map(({ id, label }) => (
            <div key={id} className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {label}
              </label>
              <StarRating category={id} value={ratings[id]} />
            </div>
          ))}

          <div className="space-y-2">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Additional Comments
            </label>
            <textarea
              id="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Please provide any additional feedback..."
              className="w-full min-h-32 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Submit Reference
          </button>
        </form>
      )}
    </div>
  );
};

export default ReferenceForm;
