import axios from "axios";

const getApiUrl = () => {
  return process.env.REACT_APP_ENV === "production"
    ? `${process.env.REACT_APP_API_URL_DEPLOYED}/`
    : `${process.env.REACT_APP_API_URL_LOCAL}/`;
};

export const API_BASE_URL = getApiUrl();
export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    Accept: "application/json",
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Only add the Authorization header if it's not already present
    if (!config.headers.Authorization) {
      const token = localStorage.getItem("token");

      // Add the Authorization header if token exists
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  },
);

// Optional: Add a response interceptor to handle token-related errors
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle unauthorized errors (e.g., token expired)
    if (error.response && error.response.status === 401) {
      // Clear token and redirect to login
      localStorage.removeItem("token");
      // You might want to redirect to login page or dispatch a logout action here
    }
    return Promise.reject(error);
  },
);
