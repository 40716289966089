import { api } from "./api";

export const subscriptionList = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/admin/stripe/subscription/list`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const createIntent = async (price) => {
  const token = localStorage.getItem("token");
  const response = await api.post(
    `/api/stripe/create/intent`,
    { price: price },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};

export const createSubscriptionSession = async (price) => {
  const token = localStorage.getItem("token");
  const response = await api.post(
    `/api/stripe/create/subscription/session`,
    { price: price },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};

export const generateInvoiceLink = async () => {
  const response = await api.get(`/api/stripe/create/invoice/link`);
  return response.data;
};
