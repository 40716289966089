import React, { useEffect, useState } from "react";
import Pica from "pica";

const ResizedImage = ({ imagePath, width, height, className, alt }) => {
  const [resizedImage, setResizedImage] = useState(null);

  useEffect(() => {
    const resizeImage = async () => {
      try {
        console.log("Starting image load...");
        const img = new Image();
        img.crossOrigin = "Anonymous"; // Handle potential CORS issues
        img.src = imagePath;

        img.onload = async () => {
          console.log("Image loaded successfully.");
          const canvas = document.createElement("canvas");
          const outputCanvas = document.createElement("canvas");
          outputCanvas.width = width;
          outputCanvas.height = height;

          const pica = Pica();
          console.log("Starting image resize...");
          await pica.resize(img, outputCanvas, {
            quality: 3,
            alpha: true,
          });

          outputCanvas.toBlob((blob) => {
            if (blob) {
              const resizedImageUrl = URL.createObjectURL(blob);
              console.log("Image resized successfully.");
              setResizedImage(resizedImageUrl);
            } else {
              console.error("Failed to create blob from resized canvas.");
            }
          }, "image/jpeg");
        };

        img.onerror = (error) => {
          console.error("Error loading image:", error);
        };
      } catch (error) {
        console.error("Error resizing image:", error);
      }
    };

    resizeImage();
  }, [imagePath, width, height]);

  if (!resizedImage) {
    return <div>Loading...</div>;
  }

  return <img src={resizedImage} alt={alt} className={className} />;
};

export default ResizedImage;
