import React from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GripVertical } from "lucide-react";

function SortableQuestionCard({ children, id }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    position: "relative",
    zIndex: isDragging ? 999 : 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div className="flex">
        <div
          {...attributes}
          {...listeners}
          className="cursor-move p-2 hover:bg-gray-100 rounded-lg transition-colors"
        >
          <GripVertical size={20} className="text-gray-400" />
        </div>
        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
}

export default function DraggableQuestionsList({
  questions,
  onQuestionsReorder,
  renderItem,
}) {

  const [activeId, setActiveId] = React.useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
    document.body.classList.add("dragging");
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    setActiveId(null);
    document.body.classList.remove("dragging");

    // Return if there is no target or the item hasn't moved
    if (!over || active.id === over.id) {
      return;
    }

    // Find the indices of the active and target items
    const oldIndex = questions.findIndex((q) => q._id === active.id);
    const newIndex = questions.findIndex((q) => q._id === over.id);

    // Check if indices are valid
    if (oldIndex === -1 || newIndex === -1) {
      console.error("Invalid indices detected. Check the IDs and array.");
      return;
    }
    // Reorder the array
    const reorderedQuestions = arrayMove(
      [...questions],
      oldIndex,
      newIndex,
    ).map((q, index) => ({
      ...q,
      order: index + 1,
    }));

    // Update the parent component with the new order
    onQuestionsReorder(reorderedQuestions);
  }
  function handleDragCancel() {
    setActiveId(null);
    document.body.classList.remove("dragging");
  }

  const sortedQuestions = React.useMemo(() => {
    return [...questions].sort((a, b) => (a.order || 1) - (b.order || 1));
  }, [questions]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext
        items={sortedQuestions.map((q) => q._id)}
        strategy={verticalListSortingStrategy}
      >
        {sortedQuestions.map((question, index) => (
          <SortableQuestionCard key={question._id} id={question._id}>
            {renderItem(question, index)}
          </SortableQuestionCard>
        ))}
      </SortableContext>

      <DragOverlay>
        {activeId ? (
          <div className="bg-white shadow-lg rounded-lg p-4 opacity-90">
            {renderItem(
              questions.find((q) => q._id === activeId),
              questions.findIndex((q) => q._id === activeId),
            )}
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
