import React, { useState, useEffect, useCallback } from "react";
import {
  Briefcase,
  CreditCard,
  CheckCircle,
  FileText,
  Clock,
  FileText as FileIcon,
} from "lucide-react";
import BillingInformation from "./BillingInformation";

import StripeSubscriptionModal, {
  handleSelectPlan,
} from "./modal/stripeSubscription";
import {
  currentSubscription,
  recentTransactions,
} from "./utils/paymentHistory";
import { generateInvoiceLink } from "./utils/stripe";
import moment from "moment";

const Billing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState([
    {
      plan: "Free Plan",
      amount: "0.00",
      renewal: "-",
      status: "active",
    },
  ]);
  const [recentTransaction, setRecentTransactions] = useState([]);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // const dummyData = weeks.map((week, index) => ({
  //   week: `Week ${index + 1}`,
  //   candidateReview: parseFloat((Math.random() * 5).toFixed(2)),
  //   questionnaireBuilder: parseFloat((Math.random() * 5).toFixed(2)),
  //   generalUse: parseFloat((Math.random() * 5).toFixed(2)),
  //   start: week.start,
  //   end: week.end,
  // }));

  // console.log(dummyData);
  //
  const handleGenerateInvoiceLink = useCallback(async () => {
    let link;
    if (!invoiceLink) {
      link = await generateInvoiceLink();
      setInvoiceLink(link);
    } else {
      link = invoiceLink;
    }
    // redirect user to link in new tab;
    window.open(link, "_blank", "noreferrer");
  }, [invoiceLink]);
  useEffect(() => {
    const getSubscriptionDetails = async () => {
      try {
        // In a real application, you would call your backend API here
        // which would then securely communicate with the Stripe API
        //let data = await currentSubscription();
        let sub = await currentSubscription();
        let details;
        if (sub.length > 0) {
          details = sub.map((data) => {
            return {
              plan: "Platform Fee",
              amount: (
                data.stripeSubscription.items[0].plan.amount / 100
              ).toFixed(2),
              renewal: new Date(data.currentPeriodEnd).toLocaleDateString(),
              status: data.status,
            };
          });
          setSubscriptionDetails(details);
        }
      } catch (err) { }
    };
    getSubscriptionDetails();
  }, []);

  useEffect(() => {
    const getRecentTransaction = async () => {
      try {
        const history = await recentTransactions();
        setRecentTransactions(history);
      } catch (err) { }
    };
    getRecentTransaction();
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg lg:p-8">
      <h2 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
        <CreditCard className="mr-2" /> Billing Information
      </h2>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4 text-gray-800 flex items-center">
          <Briefcase className="mr-2" /> Current Subscription
        </h3>
        <div className="p-4 bg-blue-50 rounded-lg">
          {subscriptionDetails.map((subs) => (
            <div>
              <p className="text-lg">
                <strong>Plan:</strong> {subs.plan}
              </p>
              <p className="text-lg">
                <strong>Price:</strong> £{subs.amount} /month
              </p>
              <p className="text-lg">
                <strong>Renewal Date:</strong> {subs.renewal}
              </p>
              <p className="text-lg">
                <strong>Status:</strong> {subs.status}
              </p>
            </div>
          ))}
          <div className="mt-4 flex space-x-4">
            {/*
            <button
              onClick={handleOpenModal}
              className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 flex items-center"
            >
              <RefreshCw className="mr-2" /> Update Subscription
            </button>
              */}
            <button
              className="bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300 flex items-center"
              onClick={handleGenerateInvoiceLink}
            >
              <FileIcon className="mr-2" /> View Invoices
            </button>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4 text-gray-800 flex items-center">
          <FileText className="mr-2" /> Recent Transactions
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg shadow-md">
            <thead className="bg-blue-600 text-white">
              <tr>
                <th className="py-2 px-4 text-left">Date</th>
                <th className="py-2 px-4 text-left">Description</th>
                <th className="py-2 px-4 text-left">Amount (£)</th>
                <th className="py-2 px-4 text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              {recentTransaction.length > 0 ? (
                recentTransaction.map((transaction, index) => (
                  <tr
                    key={index}
                    className={`border-b last:border-0 ${index % 2 === 0 ? "bg-gray-50" : ""}`}
                  >
                    <td className="py-2 px-4">
                      {moment(transaction.createdOn).format("D/M/Y")}
                    </td>
                    <td className="py-2 px-4">{transaction.description}</td>
                    <td className="py-2 px-4">£{transaction.amount}</td>
                    <td className="py-2 px-4 flex items-center">
                      {transaction.status === "completed" ? (
                        <CheckCircle className="text-green-500 mr-2" />
                      ) : (
                        <Clock className="text-yellow-500 mr-2" />
                      )}
                      {transaction.status}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center pt-10" colSpan="4">
                    <strong className="">No Entires were found</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <BillingInformation />
      <StripeSubscriptionModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onSelectPlan={handleSelectPlan}
      />
    </div>
  );
};

export default Billing;
